"use client";

import {
  TRUST_PILOT_BUSINESS_UNIT_ID,
  TRUST_PILOT_URL,
} from "@shared/constants";

import BBB from "@client/components/bbb";
import TrustBoxContainer from "@client/components/trust-pilot";

type StepFooterParams = {
  text?: string;
  showBBBWidget?: boolean;
  trustPilotEnabled?: boolean;
};

export default function LeadFormStepFooter({
  text,
  showBBBWidget = true,
  trustPilotEnabled = false,
}: StepFooterParams) {
  const trustPilotBusinessId: string = TRUST_PILOT_BUSINESS_UNIT_ID;
  const trustPilotURL: string = TRUST_PILOT_URL;

  const showTrustPilotWidget: boolean =
    trustPilotBusinessId !== "" && trustPilotURL !== "" && trustPilotEnabled;

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: text || "It's free and will not affect your credit score",
        }}
      ></span>
      {showTrustPilotWidget && <TrustBoxContainer />}
      {showBBBWidget && <BBB className="h-7" />}
    </>
  );
}

import Image from "next/image";

export default function BBB({
  className = "",
  height = 28,
  width = 133,
}: {
  className?: string;
  height?: number;
  width?: number;
}) {
  return (
    <a
      href="https://www.bbb.org/us/il/chicago/profile/financial-services/billdoctor-org-0654-1000037486/#sealclick"
      target="_blank"
      rel="nofollow"
    >
      <Image
        height={height}
        width={width}
        className={`border-none mx-auto w-auto ${className}`}
        src="https://seal-chicago.bbb.org/seals/blue-seal-200-42-bbb-1000037486.png"
        alt="BillDoctor.org BBB Business Review"
      />
    </a>
  );
}

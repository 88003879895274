"use client";

import Script from "next/script";

import { NEVERBOUNCE_PUBLIC_API_KEY } from "@shared/constants";

import postEvent from "@server/front-end-api/postEvent";

import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

export function handleNeverbounce() {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  if (typeof window === "undefined" || !visitCookie.neverbounceEnabled) {
    return;
  }

  window?.document
    ?.querySelector("body")!
    .addEventListener("nb:registered", function (event: any) {
      // Get field using id from registered event
      let field = document.querySelector(
        '[data-nb-id="' + event.detail.id + '"]',
      );

      if (!field) return;

      // Handle loading status (API request has been made)
      field.addEventListener("nb:loading", function (e: any) {
        // Do stuff while waiting on API response
      });

      // Handle results (API call has succeeded)
      field.addEventListener("nb:result", function (e: any) {
        if (
          e.detail?.result?.is(window?._nb?.settings.getAcceptedStatusCodes())
        ) {
          // Do stuff for good email
          const classList = field?.parentElement?.classList;

          if (classList?.contains("form__field_state_error")) {
            field?.parentElement?.classList.remove("form__field_state_error");
          }

          if (classList?.contains("form__field_state_warning")) {
            field?.parentElement?.classList.remove("form__field_state_warning");
            field?.classList.remove("invalid-email");
          }

          if (field?.parentElement?.lastChild?.textContent) {
            field.parentElement.lastChild.textContent = "";
          }
        } else {
          // Do stuff for bad email
          postEvent("error", {
            error: {
              error_message: `Invalid result, ${e.detail?.result?._error?.message}`,
              error_object: "Lead Form",
              error_type: "neverbounce_validation",
              path: window.location.href,
              invalid_email: field?.attributes.getNamedItem("value")?.value,
            },
          });
          if (!field?.classList.contains("invalid-email")) {
            field?.classList.add("invalid-email");
          }
        }
      });

      // Handle soft results (fails regex; doesn't bother making API request)
      field.addEventListener("nb:soft-result", function (e) {
        if (
          field?.parentElement?.classList.contains("form__field_state_warning")
        ) {
          field?.parentElement?.classList.remove("form__field_state_warning");
        }
      });
    });
}

export function Neverbounce() {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  return visitCookie?.neverbounceEnabled ? (
    <>
      <Script id="neverbounce" type="text/javascript">
        {`_NBSettings = {
            ajaxMode: true,
            apiKey: "${NEVERBOUNCE_PUBLIC_API_KEY}",
            rejectedMessage: "Please enter a valid email",
            displayPoweredBy: false,
            feedback: false,
            inputLatency: 0,
        }`}
      </Script>
      <Script
        type="text/javascript"
        src="https://cdn.neverbounce.com/widget/dist/NeverBounce.js"
      ></Script>
    </>
  ) : (
    <></>
  );
}
